<script lang="ts">
import { cva, type VariantProps } from 'cva';

export const formVariants = {
  compact: {
    true: 'form--compact',
  },
};

const classes = cva({ variants: formVariants });

export type FormProps = VariantProps<typeof classes>;
</script>

<script setup lang="ts">
import { computed } from 'vue';

import BaseLayoutGap from '../BaseLayoutGap/BaseLayoutGap.vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import BaseLoader from '../BaseLoader/BaseLoader.vue';

const props = withDefaults(
  defineProps<{
    autoSpacing?: boolean;
    compact?: boolean;
    submitLabel?: string;
    submittingLabel?: string;
    hideActions?: boolean;
  }>(),
  {
    autoSpacing: true,
    submitLabel: 'Submit',
  }
);

const emit = defineEmits<{
  submit: [];
}>();

const valid = defineModel<boolean>('valid', { default: true });
const dirty = defineModel<boolean>('dirty', { default: true });
const submitting = defineModel<boolean>('submitting', { default: false });

const computedSubmitLabel = computed(() =>
  submitting.value
    ? props.submittingLabel || props.submitLabel
    : props.submitLabel
);

const disabled = computed(
  () => !valid.value || !dirty.value || submitting.value
);

const autoSpacingElement = computed(() =>
  props.autoSpacing ? BaseLayoutGap : 'div'
);

const onSubmit = () => {
  emit('submit');
};
</script>

<template>
  <form class="form" :class="classes({ compact })" @submit.prevent="onSubmit">
    <component
      :is="autoSpacingElement"
      class="form__auto-spacing"
      direction="column"
      size="medium"
    >
      <slot />

      <div v-if="!hideActions" class="form__actions">
        <slot name="prefixActions" />
        <slot name="actions" :disabled="disabled" :submitting="submitting">
          <BaseButton type="submit" :disabled="disabled"
            ><template v-if="submitting" #leftIcon
              ><BaseLoader v-show="submitting" /></template
            >{{ computedSubmitLabel }}</BaseButton
          >
        </slot>
        <slot name="suffixActions" />
      </div>
    </component>
  </form>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.form {
  $self: &;

  width: 100%;

  &__auto-spacing {
    height: 100%;
  }

  &__actions {
    margin-top: $space-medium;
    width: 100%;
  }

  &--compact {
    #{$self}__actions {
      margin-top: 0;
    }
  }
}
</style>
