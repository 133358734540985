<script lang="ts">
import { cva, type VariantProps } from 'cva';

export const headingVariants = {
  variant: {
    secondary: 'heading--secondary',
  },
  family: {
    lato: 'heading--lato',
  },
  level: {
    mega: 'heading--mega',
    title: 'heading--title',
    1: 'heading--1',
    2: 'heading--2',
    3: 'heading--3',
    4: 'heading--4',
    5: 'heading--5',
    6: 'heading--6',
  },
  weight: {
    bold: 'heading--bold',
    semibold: 'heading--semibold',
    regular: 'heading--regular',
  },
  noMargin: {
    true: 'heading--no-margin',
  },
};

const classes = cva({ variants: headingVariants });

export type HeadingProps = VariantProps<typeof classes>;
</script>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    variant?: HeadingProps['variant'];
    level?: HeadingProps['level'];
    semanticLevel?: HeadingProps['level'];
    family?: HeadingProps['family'];
    weight?: HeadingProps['weight'];
    noMargin?: boolean;
  }>(),
  {
    level: 2,
    semanticLevel: undefined,
  }
);

const computedComponent = computed(() => {
  if (['mega', 'title'].includes(props.level as string)) {
    if (props.semanticLevel) {
      return `h${props.semanticLevel}`;
    }

    return 'h1';
  }

  const level = props.semanticLevel || props.level;

  return `h${Math.min(level as number, 6)}`;
});
</script>

<template>
  <component
    :is="computedComponent"
    class="heading"
    :class="classes({ level, variant, weight, family, noMargin })"
  >
    <slot />
  </component>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.heading {
  $self: &;

  color: theme('color-text-primary');
  line-height: $line-height-heading;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &--mega {
    font-size: $font-size-xxxlarge;
    font-weight: $font-weight-bolder;
  }

  &--title {
    font-size: $font-size-xxlarge;
    font-weight: $font-weight-bolder;
  }

  &--1 {
    font-size: $font-size-xlarge;
    font-weight: $font-weight-bolder;
  }

  &--2 {
    font-size: $font-size-large;
    font-weight: $font-weight-bolder;
  }

  &--3 {
    font-size: $font-size-medium;
    font-weight: $font-weight-regular;
  }

  &--4 {
    font-size: $font-size-default;
    font-weight: $font-weight-regular;
  }

  &--5 {
    font-size: $font-size-small;
    font-weight: $font-weight-regular;
    margin-bottom: $space-xxsmall;
  }

  &--6 {
    font-size: $font-size-xsmall;
    font-weight: $font-weight-regular;
    margin-bottom: $space-xxsmall;
  }

  &--secondary {
    color: theme('color-text-secondary');
  }

  &--regular {
    font-weight: $font-weight-regular;
  }

  &--semibold {
    font-weight: $font-weight-semibold;
  }

  &--bold {
    font-weight: $font-weight-bolder;
  }

  &--lato {
    font-family: $font-family-secondary;
  }

  &--no-margin {
    margin: 0;
  }
}
</style>
